import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';
import { SharedDataService } from '../services/shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class ChildUserGuard implements CanActivateChild {
  constructor(private localStorageService: LocalStorageService, private authService:AuthService,
              private sharedDataService: SharedDataService, private router: Router){}
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const isLoggedin = this.localStorageService.getLocalStore('loggedIn');
      if (isLoggedin) {
        return true;
      } else {
        this.authService.menuSource.next(false);
        this.sharedDataService.setLoggedIn(0);
        this.localStorageService.removeAll();
        this.router.navigateByUrl('/dashboard');
        return false;
      }
  }
}
