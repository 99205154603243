import { FormControl } from '@angular/forms';

export function noWhitespace(control: FormControl) {
    let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true }
}

export function hhmmssValidate(control: FormControl) {
    const isValid = /^(?:([01]?\d|2[0-3]):([0-5]?\d):)?([0-5]?\d)$/.test(control.value);
    return isValid ? null : { 'hhmmss': true }
}