import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { api, channelsAdd, editChannelUrl, fetchChannelUrl, targetChannels } from '../../shared/constants/url.constants';

@Injectable({
  providedIn: 'root'
})
export class ChannelsService {

  constructor(private http: HttpClient) { }

  addChannel(data) {
    const configURL = environment.apiUrl + environment.version + channelsAdd;
    return this.http.post(configURL, data)
      .pipe(
        map(
          (response: any) => {
            if (response.status_code === 200) {
              return response
            } else {
              // console.log(response.error.error.message)
            }
          }
          , (error: HttpErrorResponse) => {
            // console.log(error.error.message)
          }
        )
      );
  }

  fetchChannel(id) {
    const configURL = environment.apiUrl + environment.version + fetchChannelUrl + "/" + id;
    return this.http.get(configURL)
      .pipe(
        map(
          (response: any) => {
            if (response.status_code === 200) {
              return response
            } else {
              // console.log(response.error.error.message)
            }
          }
          , (error: HttpErrorResponse) => {
            // console.log(error.error.message)
          }
        )
      );
  }

  editChannel(data, id) {
    const configURL = environment.apiUrl + environment.version + editChannelUrl + "/" + id;
    return this.http.post(configURL, data)
      .pipe(
        map(
          (response: any) => {
            if (response.status_code === 200) {
              return response
            } else {
              // console.log(response.error.error.message)
            }
          }
          , (error: HttpErrorResponse) => {
            // console.log(error.error.message)
          }
        )
      );
  }

  fetchTargetChannels(data) {
    const configURL = environment.apiUrl + environment.version + targetChannels;
    const params = new HttpParams()
    .set('is_launch_channel', data)
    return this.http.get(configURL, {params})
      .pipe(
        map(
          (response: any) => {
            if (response.status_code === 200) {
              return response
            } else {
              // console.log(response.error.error.message)
            }
          }
          , (error: HttpErrorResponse) => {
            // console.log(error.error.message)
          }
        )
      );
  }
}
